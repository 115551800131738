@import 'variables';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  height: 100%;
  background-color: #012e3b;
}

.primaryHeading {
  font-size: 12em;
  font-weight: bold;
  text-align: center;
  color: $orange;
  width: 100%;
}

.text {
  font-size: 20px;
  color: $white-lightened;
  text-align: center;
}

@media (max-width: $maxMobileWidth) {
  .wrapper {
    padding: 30px;
  }
  .primaryHeading {
    font-size: 5em;
  }
  .text {
    font-size: 10px;
  }
}

@media (max-width: $tabletBigWidth) {
  .primaryHeading {
    font-size: 8em;
  }
  .text {
    font-size: 15px;
  }
}
